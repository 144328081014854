<template lang="pug">
v-layout(
  flex
  justify-center
)
    v-btn(
      large
      color="secondary"
      @click="addTopProductsToCart"
    )
      | Add Top {{topProducts}} Products

</template>

<script>
import parts from '@/data/parts.json'
export default {
  name: 'TopProductsButton',
  props: {
    topProducts: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    data: parts
  }),
  computed: {
    items () {
      const { OrderRow } = this.$FeathersVuex.api
      const rows = OrderRow.findInStore({ query: { __isTemp: true }, temps: true })
      if (!rows.data.length) {
        return 0
      } else {
        return rows
      }
    },
    topTwentyFive () {
      return this.data.topTwentyFive
    },
    topFifty () {
      return this.data.topFifty
    }
  },
  methods: {
    addTopProductsToCart () {
      const { OrderRow } = this.$FeathersVuex.api
      const itemsClone = this.items.data
      const itemPartNumbers = this.items.data?.map(item => item.partNumber)
      if (this.topProducts === 25) {
        if (this.items === 0) {
          this.topTwentyFive.map(product => {
            // eslint-disable-next-line no-new
            new OrderRow({ qty: 1, partNumber: product.pn })
          })
          return
        }
        if (this.items !== 0) {
          this.topTwentyFive.map(product => {
            itemsClone.map(item => {
              if (product.pn === item.partNumber) {
                item.qty++
              }
            })
            if (this.items.data.length && !itemPartNumbers.includes(product.pn)) {
              // eslint-disable-next-line no-new
              new OrderRow({ qty: 1, partNumber: product.pn })
            }
          })
        }
      }
      if (this.topProducts === 50) {
        if (this.items === 0) {
          this.topFifty.map(product => {
            // eslint-disable-next-line no-new
            new OrderRow({ qty: 1, partNumber: product.pn })
          })
          return
        }
        if (this.items !== 0) {
          this.topFifty.map(product => {
            itemsClone.map(item => {
              if (product.pn === item.partNumber) {
                item.qty++
              }
            })
            if (this.items.data.length && !itemPartNumbers.includes(product.pn)) {
              // eslint-disable-next-line no-new
              new OrderRow({ qty: 1, partNumber: product.pn })
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
